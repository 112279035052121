// const menu = document.querySelector(".menu");
// const siteContainer = document.querySelector(".site-container");

// const menuBtn = document.querySelector(".burger");
// const eqMenuBtn = document.querySelector(".eq__menu");
// const header = document.querySelector(".header");
// menuBtn.addEventListener("click", (e) => {
//   e.preventDefault();

//   let isActive = menu.classList.toggle("active");
//   siteContainer.classList.toggle("menu-active");

//   document.body.style.overflow = isActive ? "hidden" : null;
// });

// eqMenuBtn.addEventListener("click", (e) => {
//   let isActive = menu.classList.toggle("active");
//   siteContainer.classList.toggle("menu-active");

//   header.style.transform = isActive ? "translateY(0)" : "translateY(-110%)";
//   document.body.style.overflow = isActive ? "hidden" : null;
// });
